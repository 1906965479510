
    import { Component, Vue } from 'vue-property-decorator';
    import auth from '@/store/auth/auth';
    import { searchCustomers } from '@/api/ErpCustomers';
    import { ErpCustomer } from '@/interfaces/Customer';

    @Component
    export default class CustomerSearch extends Vue {
        isFetching = false;
        data: ErpCustomer[] = [];

        formatCustomer(value: ErpCustomer): string {
            let res = value.name;

            if (value.email) {
                res += `, ${value.email}`;
            }

            if (value.street) {
                res += `, ${value.street}`;
            }

            if (value.zip) {
                res += `, ${value.zip}`;
            }

            if (value.city) {
                res += `, ${value.city}`;
            }

            return `${res} (${value.id})`;
        }

        async getAsyncData(query: string) {
            // don't query when smaller than three characters.
            if (!query.length || query.length < 3) {
                this.data = [];
                return;
            }

            // start fetching
            this.isFetching = true;

            let response = await searchCustomers(query);

            if (response.status === 401 || response.status === 422) {
                await auth.dispatchRefreshToken();
                response = await searchCustomers(query);
            }

            if (response.parsedBody) {
                this.data = [];

                for (const partner of response.parsedBody) {
                    this.data.push(partner);
                }
            }

            // stop fetching
            this.isFetching = false;
        }
    }
