
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { BModalComponent } from 'buefy/types/components';
    import { ContractGeneratorRequest } from '@/interfaces/contracts/ContractGeneratorRequest';

    @Component
    export default class MultipleOfferChooser extends Vue {
        @Prop() offerRequests!: ContractGeneratorRequest[];
        chosenOffer: ContractGeneratorRequest | null = null;

        choose() {
            this.$emit('offerChosen', this.chosenOffer);
        }

        close() {
            (this.$parent as BModalComponent).close();
        }
    }
