import { get, HttpResponse } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { ContractGeneratorRequest } from '@/interfaces/contracts/ContractGeneratorRequest';

/**
 * Retrieve the draft by its 'offer' number.
 *
 * @param number
 */
export async function getOffer(number: string): Promise<HttpResponse<ContractGeneratorRequest[]>> {
    return await get<ContractGeneratorRequest[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/ic/offers/${number}/`,
        TokenType.ACCESS,
    );
}
