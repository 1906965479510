import { get, HttpResponse } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { ErpCustomer } from '@/interfaces/Customer';

export async function searchCustomers(query: string): Promise<HttpResponse<ErpCustomer[]>> {
    return await get<ErpCustomer[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/partners/search/?query=${query}`,
        TokenType.ACCESS,
    );
}

export async function fetchRealCustomers(): Promise<HttpResponse<ErpCustomer[]>> {
    return await get<ErpCustomer[]>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/erp/partners/`,
        TokenType.ACCESS,
    );
}
