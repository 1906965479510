import { get, HttpResponse } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { ContractGeneratorRequest } from '@/interfaces/contracts/ContractGeneratorRequest';

/**
 * Retrieve an order by its number.
 *
 * @param number
 */
export async function getOrder(number: string): Promise<HttpResponse<ContractGeneratorRequest>> {
    return await get<ContractGeneratorRequest>(
        `${process.env.VUE_APP_CRM_SERVICE_HOST}/api/v1/ic/order/${number}/`,
        TokenType.ACCESS,
    );
}
