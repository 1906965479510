
    import { Component, Vue } from 'vue-property-decorator';
    import { getOffer } from '@/api/Offers';
    import auth from '@/store/auth/auth';
    import { generateContract } from '@/api/Contracts';
    import { ErpCustomer } from '@/interfaces/Customer';
    import { buildContractERPUrl } from '@/utils/misc';
    import { alertError, alertInfo, confirm } from '@/utils/dialogs';
    import MultipleOfferChooser from '@/components/contracts/MultipleOfferChooser.vue';
    import SearchCar from '@/components/cars/SearchCar.vue';
    import { ErpCar } from '@/interfaces/cars/ErpCar';
    import { getOrder } from '@/api/Orders';
    import { ContractGeneratorRequest } from '@/interfaces/contracts/ContractGeneratorRequest';
    import { ContractGeneratorRequestType } from '@/enums/contracts/ContractGeneratorRequestType';
    import CustomerSearch from '@/components/CustomerSearch.vue';
    import { StaffcalcCategory } from '@/enums/offer/StaffcalcCategory';

    @Component({
        components: {
            CustomerSearch,
            SearchCar,
            MultipleOfferChooser,
        },
    })
    export default class ContractAdd extends Vue {
        activeTab = 0;

        offerNumber = '';
        orderNumber = '';
        isMultipleOfferChooserActive = false;
        offersToChoose: ContractGeneratorRequest[] = [];

        selectedExistingCustomer: ErpCustomer | null = null;
        selectedCar: ErpCar | null = null;

        // order/offer the contract should be generated from
        sourceObj: ContractGeneratorRequest | null = null;

        onCustomerChanged(customer: ErpCustomer) {
            this.selectedExistingCustomer = customer;
        }

        onCarChanged(car: ErpCar) {
            this.selectedCar = car;
        }

        onOfferChosen(offer: ContractGeneratorRequest) {
            // offer was chosen, set source
            this.sourceObj = offer;
            this.isMultipleOfferChooserActive = false;

            this.confirmGenerating(ContractGeneratorRequestType.OFFER);
        }

        async onOrderGenerateClick() {
            // get the order
            const res = await this.fetchOrder(this.orderNumber);

            if (!res) {
                throw new Error('Order is undefined');
            }

            this.sourceObj = res;
            await this.confirmGenerating(ContractGeneratorRequestType.ORDER);
        }

        /**
         * Click handler to write to the 'draft' member variable (draft to be processed)
         */
        async onOfferGenerateClick() {
            // get drafts, they come as array
            const res = await this.fetchOffer(this.offerNumber);

            if (res) {
                if (res.length > 1) {
                    // unfortunately there is more than one draft, choose one
                    // process multiple drafts, show modal and choose one
                    this.isMultipleOfferChooserActive = true;
                    // write both customers to member/prop for modal window
                    this.offersToChoose = res;

                    // stop execution
                    return;
                } else {
                    // just a single draft was fetched -> best case
                    this.sourceObj = res[0];

                    await this.confirmGenerating(ContractGeneratorRequestType.OFFER);
                }
            }
        }

        async confirmGenerating(requestType: ContractGeneratorRequestType) {
            if (!this.sourceObj) {
                throw new Error('Order/Offer is undefined');
            }

            if (
                this.sourceObj.category === StaffcalcCategory.RENEWAL &&
                (!this.selectedCar || !this.selectedExistingCustomer)
            ) {
                alertError(
                    'Fehler',
                    'Bei einer Vertragsverlängerung muss ein Bestandsfahrzeug und ein Bestandskunde gewählt werden.',
                );
                return;
            }

            let email;

            if (this.selectedExistingCustomer) {
                email = this.selectedExistingCustomer.email;
            } else {
                email = this.sourceObj.customer.mail_address;
            }

            let text = `Soll ein Vertrag anhand der Nummer <b>${this.sourceObj.number}</b> für
                                <b>${email}</b> erstellt werden?`;

            if (this.sourceObj.price_result.car_purchase_data?.order_note) {
                text = `${text}<br><br><b>Bestellanmerkung</b>: ${this.sourceObj.price_result.car_purchase_data?.order_note}`;
            }

            const { result, dialog } = await confirm(
                'EASING-Vertrag generieren',
                text,
                'Generieren',
            );

            if (result) {
                // 'OK' clicked, closing dialog
                dialog.close();

                // start loading
                const loading = this.$buefy.loading.open({
                    isFullPage: true,
                });

                // generate the EASING contract
                await this.startGenerating(this.sourceObj, requestType);

                // close loading
                loading.close();
            }
        }

        async startGenerating(obj: ContractGeneratorRequest, type: ContractGeneratorRequestType) {
            const res = await this.generateContract(
                type,
                obj,
                this.selectedExistingCustomer,
                this.selectedCar,
            );

            if (res) {
                const contractLink = `
              <p><a href="${buildContractERPUrl(
                  res.contract_id,
              )}" target="_blank">Zum Datensatz</a></p>`;

                alertInfo(
                    'Info',
                    `<p>Der EASING-Vertrag im ERP wurde erfolgreich erstellt.</p>${contractLink}`,
                );
            }
        }

        /**
         * Start the contract generating.
         *
         * @param type contract generator request type
         * @param obj order or offer
         * @param customer optional customer
         * @param car optional car
         */
        async generateContract(
            type: ContractGeneratorRequestType,
            obj: ContractGeneratorRequest,
            customer: ErpCustomer | null,
            car: ErpCar | null,
        ) {
            let response = await generateContract(type, obj, customer, car);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await generateContract(type, obj, customer, car);
            }

            if (response && response.status === 409) {
                alertError('Fehler', 'Dieses Angebot wurde bereits generiert.');
                return;
            }

            if (response && response.status === 422) {
                alertError(
                    'Fehler',
                    'Lieferzeit "auf Anfrage": Vertrag kann nicht generiert werden.',
                );
                return;
            }

            const result = response.parsedBody;

            if (result) {
                return result;
            }

            throw new Error('Error while generating contract.');
        }

        get isOfferButtonDisabled() {
            return !this.offerNumber.match(/^(DE|AT|FR|CH)?\d{8,14}(C|O|SC)?$/);
        }

        get isOrderButtonDisabled() {
            return !this.orderNumber.match(/^(DE|AT|FR|CH)?\d{9,10}O$/);
        }

        clearOfferIconClick() {
            this.offerNumber = '';
        }

        clearOrderIconClick() {
            this.orderNumber = '';
        }

        async fetchOrder(orderNumber: string) {
            let response = await getOrder(orderNumber);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await getOrder(orderNumber);
            }

            if (response && response.status === 404) {
                alertError(
                    'Bestellnummer nicht gefunden',
                    'Leider konnte die angegebene Bestellnummer im System nicht gefunden werden.',
                );
                return;
            }

            if (!response.parsedBody) {
                throw new Error('Body is undefined');
            }

            const order: ContractGeneratorRequest = {
                ...response.parsedBody,
                price_result: response.parsedBody['data']['price_result'],
            };

            if (order) {
                return order;
            }

            throw new Error('Error while fetching order.');
        }

        async fetchOffer(offerNumber: string) {
            let response = await getOffer(offerNumber);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await getOffer(offerNumber);
            }

            if (response && response.status === 404) {
                alertError(
                    'Angebotsnummer nicht gefunden',
                    'Leider konnte die angegebene Angebotsnummer nicht im System gefunden werden.',
                );
                return;
            }

            const offer = response.parsedBody;

            if (offer) {
                return offer;
            }

            throw new Error('Error while fetching offer/s.');
        }

        /**
         * Route could be called like /contracts/add/<number>/
         * Fill the inputs with the given number.
         */
        handleObjectNumberParam() {
            if ('obj_number' in this.$route.params && this.$route.params.obj_number) {
                // offer/order number here, write it
                const number = this.$route.params.obj_number;

                if (number.endsWith('C') || number.endsWith('SC')) {
                    this.offerNumber = number;
                    this.activeTab = 0;
                }

                if (number.endsWith('O')) {
                    this.orderNumber = number;
                    this.activeTab = 1;
                }
            }
        }

        mounted() {
            this.handleObjectNumberParam();
        }
    }
